import React from 'react'
import './styles.css'

export const Header: React.FC = () => {
    return (
        <header>
            <a href="https://www.pricetowinsolutions.com/" title="Price To Win Solutions">
                <img src="/logo.png" alt="Price To Win Solutions" width="165" />
            </a>
        </header>
    )
}
