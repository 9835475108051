export const formatDollar = (num: number): string => {
    if (!num) {
        return "$0.00"
    }

    const p = num.toFixed(2).split(".")

    return ["$", p[0].split("").reverse().reduce((acc, num, i) => {
        return num + (i && !(i % 3) ? "," : "") + acc
    }, "."), p[1]].join("")
}
