import React, { useEffect, useState, useCallback } from 'react';
import { ResultsResponse, getResults } from './requests/search'
import { SearchInput } from './components/SearchInput'
import { DisplaySelectedCodes } from './components/DisplaySelectedCodes'
import { updateURLParameter } from './utils/updateUrlParameters'
import { ShowResults } from './components/ShowResults'

import './App.css';

const App: React.FC = () => {
    const [loading, setLoading] = useState(false)
    const [initiated, setInitiated] = useState(false)
    const [selectedNAICScodes, setSelectedNAICScodes] = useState<string[]>([])
    const [failed, setFailed] = useState(false)
    const [results, setResults] = useState<ResultsResponse>()

    const onCodeSelected = async (code: string): Promise<void> => {
        if (selectedNAICScodes.find((selectedCode) => selectedCode === code)) {
            return
        }

        setSelectedNAICScodes([
            ...selectedNAICScodes,
            code
        ])

        // const res = await getSearchResults({
        //     NAICS: code
        // })
    }

    const onCodeRemove = (codeToRemove: string): void => {
        setSelectedNAICScodes(
            selectedNAICScodes.filter((selectedCode) => selectedCode !== codeToRemove)
        )
    }

    useEffect(() => {
        if (!initiated) {
            return
        }

        const newUrl = updateURLParameter(
            window.location.href,
            'c',
            selectedNAICScodes.join('.')
        )

        window.history.replaceState('', '', newUrl)
    }, [selectedNAICScodes, initiated])


    const analyzeCodes = useCallback(async (): Promise<void> => {
        if (loading || !selectedNAICScodes.length) {
            return
        }

        setFailed(false)
        setResults(undefined)
        setLoading(true)

        const formatCodesForAPI = (): string => {
            return selectedNAICScodes.map(
                (code) => Number(code.split(' ')[0])
            ).join('.')
        }

        try {
            const res = await getResults({
                codes: formatCodesForAPI()
            })
            setResults(res.data)
        } catch (err) {
            setFailed(true)
        }

        setLoading(false)
    }, [selectedNAICScodes, loading])

    useEffect(() => {
        if (initiated) {
            return
        }

        if (window.location.search) {
            // eslint-disable-next-line
            const queries = window.location.search.replace('?', '').split('&').reduce((r:any, e:any):any => (r[e.split('=')[0]] = decodeURIComponent(e.split('=')[1]), r), {});
            
            if (queries.c) {
                setSelectedNAICScodes(
                    queries.c.replace(/\+/g,' ').split('.').map((code: string) => code.trim())
                )
                analyzeCodes()
            }
        }

        setInitiated(true)
    }, [analyzeCodes, initiated])

    const onAnalyzeClicked = (e: React.ChangeEvent<HTMLFormElement>): void => {
        e.preventDefault()
        e.stopPropagation()
        analyzeCodes()
    }

    return (
        <form className="App" onSubmit={onAnalyzeClicked}>
            <SearchInput onCodeSelected={onCodeSelected} disabled={loading} />
            <DisplaySelectedCodes selectedCodes={selectedNAICScodes} onCodeRemove={onCodeRemove} />
            { failed && (<p className="error-feedback">
                Something went wrong trying to analyze these NAICS codes. Please try again.
            </p>)}
            <button
                className="analyze-btn"
                type="submit"
                disabled={selectedNAICScodes.length === 0} aria-disabled={selectedNAICScodes.length === 0}
                title="Analyze selected NAICS codes"
                aria-label="Analyze selected NAICS codes"
            >
                { loading ? 'Analyzing...' : 'Analyze' }
            </button>

            { results && <ShowResults results={results} /> }
        </form>
    );
}

export default App;
