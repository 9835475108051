import React from 'react'
import { ResultsResponse } from '../../requests/search'
import { formatDollar } from '../../utils/formatDollarAmount'

import './styles.css'

type Props = {
    results: ResultsResponse
}

const TableRowForNAICScode: React.FC<{
    year: number
    NAICScode: string,
    yearIndex: number,
    results: ResultsResponse
}> = ({
    NAICScode,
    yearIndex,
    results,
    year
}) => {
    const employeesPerFirm = results.employeesPerFirm[NAICScode][yearIndex]
    const employeeSalaries = results.employeeSalaries[NAICScode][yearIndex]
    const payRollPerFirm = results.payRollPerFirm[NAICScode][yearIndex]
    const revenuePerFirm = results.revenuePerFirm[NAICScode][yearIndex]
    const wrapRates = results.wrapRates[NAICScode][yearIndex]
    const numberOfEstablishments = results.numberOfEstablishments[NAICScode][yearIndex]
    const numberOfEmployees = results.numberOfEmployees[NAICScode][yearIndex]

    const determineAverageRate = (): string => {
        if (!Number(employeeSalaries) || !Number(wrapRates)) {
            return 'No data available'
        }

        return formatDollar( (employeeSalaries / 2080) * wrapRates )
    }
    
    return (
        <tr>
            <td> { /* year */}
                { year }
            </td>

            <td title="Total Establishments">
                { Number(numberOfEstablishments) ? numberOfEstablishments.toLocaleString('en') : 'No data available.' }
            </td>

            <td title="Total Employees">
                { Number(numberOfEmployees) ? numberOfEmployees.toLocaleString('en') : 'No data available.' }
            </td>

            <td title="Employee Salaries">
                { Number(employeeSalaries) ? formatDollar(employeeSalaries) : 'No data available' }
            </td>

            <td title="Payroll per Establishment">
                { Number(payRollPerFirm) ? formatDollar(payRollPerFirm) : 'No data available' }
            </td>

            <td title="Revenue per Establishment">
                { Number(revenuePerFirm) ? formatDollar(revenuePerFirm) : 'No data available' }
            </td>

            <td title="Employees per Establishment">
                { Number(employeesPerFirm) ? employeesPerFirm.toFixed(2) : 'No data available.' }
            </td>

            <td title="Mark Up">
                { Number(wrapRates) ? wrapRates.toFixed(2) : 'No data available' }
            </td>

            <td title="Average Rate at 2080 hours">
                { determineAverageRate() }
            </td>
        </tr>
    )
}

export const TableView: React.FC<Props> = ({
    results
}) => {
    const years = [
        2002,
        2007,
        2012,
        2017,
        2022
    ]

    const allNAICScodes = Object.keys(results.revenuePerFirm)

    return (
        <>
            {
                allNAICScodes.map((NAICS) => <>
                    <h2 className="table-title">
                        { NAICS }
                    </h2>
                    <table className="results-table">
                        <thead>
                            <th>
                                Year
                            </th>
                            <th title="Total Establishments">
                                Total Est.
                            </th>

                            <th title="Total Employees">
                                Total Emp.
                            </th>

                            <th title="Employee Salaries">
                                Emp. Salaries
                            </th>

                            <th title="Payroll per Establishment">
                                Payroll Per Est.
                            </th>

                            <th title="Revenue per Establishment">
                                Revenue Per Est.
                            </th>

                            <th title="Employees per Establishment">
                                Emp. Per Est.
                            </th>

                            <th title="Mark Up Rate">
                                Mark Up
                            </th>

                            <th title="Average Rate at 2080 hours">
                                Ave. Rate
                            </th>
                        </thead>
                        <tbody>
                            {
                                years.map((year, yearIndex) => (
                                    <TableRowForNAICScode
                                        year={year}
                                        NAICScode={NAICS}
                                        yearIndex={yearIndex}
                                        results={results}
                                    />
                                ))
                            }
                        </tbody>
                    </table>
                </>)
            }
        </>
    )
}
