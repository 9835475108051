import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartOptions
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ResultsResponse } from '../../requests/search'
import { randomHexColor } from '../../utils/randomHexColor'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

type Props = {
    results: ResultsResponse
    title: string
    NAICS: string
}

export const EmployeesAndSalariesChart: React.FC<Props> = ({
    title,
    results,
    NAICS
}) => {
    const prepareDataForLineChart = () => {
        const labels = ['2002', '2007', '2012', '2017', '2022']

        const colors = [
            randomHexColor('40'),
            randomHexColor('40'),
            randomHexColor('40')
        ]

        const datasets = [
            {
                label: 'Average Employee Salary',
                data: results.employeeSalaries[NAICS],
                borderColor: colors[0],
                backgroundColor: colors[0],
                fill: true,
                yAxisID: 'y',
            },
            {
                label: 'Average Employees per Firm',
                data: results.employeesPerFirm[NAICS],
                borderColor: colors[1],
                backgroundColor: colors[1],
                fill: true,
                yAxisID: 'y1',
            }
        ]

        return {
            labels,
            datasets
        }
    }

    const options: ChartOptions<'line'> = {
        responsive: true,
        layout: {
            padding: 25
        },
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: title
            },
        },
        scales: {
            y: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
            },
            y1: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                grid: {
                    drawOnChartArea: false,
                },
            },
        }
    };

    return <Line options={options} data={prepareDataForLineChart()} />;
}

