import { $axios } from './axios'

export type ResultsResponse = {
    employeesPerFirm: {
        [NAICScode: string]: number[] // 2002, 2007, 2012, 2017, 2022
    }
    employeeSalaries: {
        [NAICScode: string]: number[]
    }
    payRollPerFirm: {
        [NAICScode: string]: number[]
    }
    revenuePerFirm: {
        [NAICScode: string]: number[]
    }
    wrapRates: {
        [NAICScode: string]: number[]
    }
    sizeStandards: {
        [NAICScode: string]: {
            millionsOfDollars?: number
            numberOfEmployees?: number
        }
    }
    numberOfEstablishments: {
        [NAICScode: string]: number[]
    }
    numberOfEmployees: {
        [NAICScode: string]: number[]
    }
    usaSpending: {
        [fullNaicsCode: string]: {
            year: number
            amount: number
            codes: string[]
        }[]
    }
}

export const getResults = (params: {
    codes: string
}): Promise<{
    data: ResultsResponse
}> => $axios.get('/search', {
    params
})
