import React from 'react'
import { ResultsResponse } from '../../requests/search'

type Props = {
    results: ResultsResponse
}

export const ShowSizeStandards: React.FC<Props> = ({ results }) => {
    const codes = Object.keys(results.sizeStandards)

    const isNotApplicable = (inSizeStandards: {
        millionsOfDollars?: number
        numberOfEmployees?: number
    }): boolean => {
        if (!inSizeStandards) {
            return true
        }

        if (!inSizeStandards.millionsOfDollars && !inSizeStandards.numberOfEmployees) {
            return true
        }

        return false
    }

    return (
        <div className="size-standards">
            <h2 className="table-title">
                Small Business Size Standards
            </h2>
            <table className="results-table">
                <thead>
                    <th>NAICS</th>
                    <th>Millions of Dollars</th>
                    <th>Number of Employees</th>
                </thead>
                <tbody>
                    {
                        codes.map((code) => {
                            if (isNotApplicable(results?.sizeStandards[code])) {
                                return (
                                    <tr key={code}>
                                        <td>{ code }</td>
                                        <td>N/A</td>
                                        <td>N/A</td>
                                    </tr>
                                )
                            }

                            return (
                                <tr key={code}>
                                    <td>{ code }</td>
                                    <td>{ results?.sizeStandards[code]?.millionsOfDollars ? `$${results?.sizeStandards[code]?.millionsOfDollars}` : '-' }</td>
                                    <td>{ results?.sizeStandards[code]?.numberOfEmployees || '-' }</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

