import React from 'react'
import { ResultsResponse } from '../../requests/search'
import { TableView } from '../../components/TableViewOfResults'
import { ShowSizeStandards } from '../../components/ShowSizeStandards'
import { PayrollRevenueAndMarkupChart } from '../../components/PayrollRevenueAndMarkupChart'
import { EmployeesAndSalariesChart } from '../../components/EmployeesAndSalariesChart'
import { EstablishmentsAndEmployeesChart } from '../../components/EstablishmentsAndEmployeesChart'
import { UsaSpendingTable } from '../../components/UsaSpendingTable'

import './styles.css'

type Props = {
    results: ResultsResponse
}

export const ShowResults: React.FC<Props> = ({
    results
}) => {
    const allNAICScodes = Object.keys(results.payRollPerFirm)

    if (!results) {
        return null
    }

    return (
        <div className="results-container">
            <TableView results={results} />
            <ShowSizeStandards results={results} />

            <UsaSpendingTable results={results} />

            { /* Jon: "delete that chart potentially as average salary is probably more meaningful." */ }
            { /* <OneResultLineChart getColorForCode={getColorForCode} title="Payroll" naicsData={payRollPerFirm} /> */ }

            { /* Labor multiplier/WRAP rate shown on new chart */ }
            { /* <OneResultLineChart getColorForCode={getColorForCode} title="Labor Multiplier" naicsData={wrapRates} /> */ }
            {
                allNAICScodes.map((NAICS) => (
                    <>
                        <EmployeesAndSalariesChart
                            title={`Employee Salaries & Employees per Firm - ${NAICS}`}
                            results={results}
                            NAICS={NAICS}
                        />
                        <PayrollRevenueAndMarkupChart
                            title={`Average Markups - ${NAICS}`}
                            results={results}
                            NAICS={NAICS}
                        />
                        <EstablishmentsAndEmployeesChart
                            title={`Total Establishments and Employees - ${NAICS}`}
                            results={results}
                            NAICS={NAICS}
                        />
                    </>
                ))
            }
        </div>
    )
}

