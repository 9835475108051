import React from 'react'
import { OneSelectedCode } from '../OneSelectedCode'

import './styles.css'

type Props = {
    selectedCodes: string[]
    onCodeRemove: (codeToRemove: string) => void
}

export const DisplaySelectedCodes: React.FC<Props> = ({
    selectedCodes,
    onCodeRemove
}) => {
    return (
        <div className="selected-codes-container">
            {
                selectedCodes.map((code) => <OneSelectedCode key={code} code={code} onCodeRemove={onCodeRemove} />)
            }
        </div>
    )
}

