import React from 'react'
import './styles.css'

type Props = {
    code: string
    onCodeRemove: (codeToRemove: string) => void
}

export const OneSelectedCode: React.FC<Props> = ({
    code,
    onCodeRemove
}) => {
    const handleRemove = (): void => {
        onCodeRemove(code)
    }

    return (
        <span className="one-selected-code">
            { code }
            <button type="button" aria-label={`Remove ${code}`} title={`Remove ${code}`} onClick={handleRemove}>
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M12 1.05L10.95 0L6 4.95L1.05 0L0 1.05L4.95 6L0 10.95L1.05 12L6 7.05L10.95 12L12 10.95L7.05 6L12 1.05Z" fill="black"/> </svg>
            </button>
        </span>
    )
}

