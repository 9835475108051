import React, { useState, useCallback, useMemo } from 'react'
import { debounce } from 'debounce'
import { searchNAICSCodes } from '../../requests/codes'

import './styles.css'

type Props = {
    onCodeSelected: (code: string) => void
    disabled: boolean
}

export const SearchInput: React.FC<Props> = ({ onCodeSelected, disabled }) => {
    const [autoCompleteCodes, setAutoCompleteCodes] = useState<string[]>([])
    const [loadingOptions, setLoadingOptions] = useState(false)
    const [focused, setFocused] = useState(false)

    const debounceCached = useMemo(() => debounce, [])

    // eslint-disable-next-line
    const getAutoCompleteOptions = useCallback(
        debounceCached(async (e: React.ChangeEvent<HTMLInputElement>) => {
            if (disabled) {
                return
            }

            if (!e.target.value) {
                return setAutoCompleteCodes([]) }

            try {
                setLoadingOptions(true)
                const res = await searchNAICSCodes({ searchTerm: e.target.value })
                setAutoCompleteCodes(res.data)
            } catch (err) {
                setAutoCompleteCodes([])
            }
            setLoadingOptions(false)
        }, 300)
    , [disabled, debounceCached])

    const showOptions = useMemo(() => {
        if (disabled) {
            return false
        }

        return focused
    }, [disabled, focused])

    return (
        <div className="naics-search-input-container">
            <label htmlFor="NAICS">
                Select NAICS codes:
            </label>
            <input
                type="text"
                name="q"
                placeholder="Start typing a NAICS code or term..."
                onChange={getAutoCompleteOptions}
                disabled={disabled}
                autoComplete="off"
                onFocus={() => setFocused(true)}
                onBlur={() => setTimeout(() => setFocused(false), 300)}
            />
            <div className={`options ${showOptions ? 'show' : ''}`.trim()} role="dialog" aria-hidden={showOptions}>
                { loadingOptions && <button type="button" disabled>Loading...</button> }
                {
                    autoCompleteCodes.length === 0 && <span>No results</span>
                }
                {
                    autoCompleteCodes.map((code) => (
                        <button
                            key={code}
                            type="button"
                            name="NAICS"
                            value={code}
                            aria-label={code}
                            disabled={disabled}
                            onClick={() => onCodeSelected(code)}
                            title={`Select ${code}`}
                        >{ code }</button>
                    ))
                }
            </div>
        </div>
    )
}

