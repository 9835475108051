import React from 'react'
import { ResultsResponse } from '../../requests/search'
import { formatDollar } from '../../utils/formatDollarAmount'

type Props = {
    results: ResultsResponse
}

export const UsaSpendingTable: React.FC<Props> = ({ results }) => {
    const selectedNaicsCodes = Object.keys(results.usaSpending)

    const determineSpendingChangePercentage = (
        thisYear: { year: number, amount: number },
        lastYear: { year: number, amount: number }
    ): string => {
        if (!lastYear || !lastYear.amount) {
            return '-'
        }

        const difference = thisYear.amount - lastYear.amount
        const percentage = (difference / lastYear.amount) * 100
        const plusOrMinus = percentage < 0 ? '-' : '+'
        return `${plusOrMinus} ${Math.abs(percentage).toFixed(2)} %`
    }

    return (
        <div className="usa-spending-results">
            {
                selectedNaicsCodes.map((naics) => (<>
                    <h2 className="table-title">
                        {naics} <br/> Government Contract Spending
                    </h2>
                    <table className="results-table">
                        <tr>
                            <th>
                                Year
                            </th>
                            <th>
                                Dollars Spent
                            </th>
                            <th>
                                % Change
                            </th>
                        </tr>
                        {
                            results.usaSpending[naics].map((spendingForYear, index) => (
                                <tr key={spendingForYear.year}>
                                    <td>{spendingForYear.year}</td>
                                    <td>{formatDollar(spendingForYear.amount)}</td>
                                    <td>{
                                        determineSpendingChangePercentage(spendingForYear, results.usaSpending[naics][index - 1])
                                    }</td>
                                </tr>
                            ))
                        }
                    </table>
                    <details>
                        <summary>View All NAICS codes used in table</summary>
                        <ul>
                            {results.usaSpending[naics][0].codes.map((code) => (<li>{code}</li>))}
                        </ul>
                    </details>
                </>))
            }
        </div>
    )
}

